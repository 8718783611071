import { toJS, makeObservable, observable, action, computed } from 'mobx'
import moment from 'moment'
import queryString from 'query-string'
import { isEmpty } from 'utils/lodash.utils'

import { prevLessonId, nextLessonId } from 'utils/lessonPages.utils'
import { setFavicon } from 'utils/dom.utils'
import { getNestedChildren } from 'utils/product.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { getElopageConfig } from 'utils/elopageConfig.utils'

import {
  ACCESS_LIMITED_MODAL_MODE,
  CHECK_ACCESS_DELAY,
  COURSE_FORMS,
  COURSE_SESSION_PUBLISH_STATES,
  LESSON_STATUSES_PUBLISH_STATES,
} from 'constants/courses.constants'

import { PAYER_PRODUCTS_PATH } from 'constants/routes/payer.constants'

import { DEFAULT_FAVICON } from 'constants/general.constants'
import { LESSON_QUIZ_OPTION_KEY, LESSON_COMMENTS_OPTION_KEY, COMMUNITY_KEY } from 'constants/options.constants'
import { desktopResolution } from 'constants/browser.constants'
import { COMMUNITY_MEMBERS_ROLES } from 'constants/communities.constants'

import { apiClient } from 'utils/requests.utils'
import { createCourseSessionApi, CourseSessionsApi } from '../api/courseSessions.api'
import { PayerRootStore } from './payerRoot.store'

const defaultCourseSession = {
  id: null,
  product: {
    name: '',
  },
  seller: {
    id: undefined,
  },
  sellableId: undefined,
  overByTime: false,
  publishState: '',
  blocked: false,
}

const defaultActiveLesson = {
  id: undefined,
  quizEnabled: false,
  commentsAllowed: false,
  fbCommentsAllowed: false,
  contentPageBeforeId: undefined,
  contentPageId: undefined,
  contentPageAfterId: undefined,
  static: false,
  active: false,
  name: '',
  isCategory: false,
}

const defaultCourseThemeData = {
  form: '',
  prefs: {
    showFavicon: false,
    favicon: '',
    showOverview: false,
  },
}

const defaultActiveLessonStatus = {
  id: undefined,
  shouldCompleteQuiz: false,
  assignedOrderId: undefined,
  publishState: '',
  publishDate: '',
  endDate: '',
}

export class CourseViewsStore {
  declare api: CourseSessionsApi
  @observable inited = false
  @observable loading = false
  @observable cmpProps

  @observable mobileMenuActive = false
  @observable mobileSideMenuActive = false
  @observable showRetakeQuiz = false
  @observable showQuizResultModal = false
  @observable showQuizResults = false

  @observable courseSession = defaultCourseSession
  @observable product = {}
  @observable lessons = []
  @observable activeLesson = defaultActiveLesson
  @observable activeLessonStatus = defaultActiveLessonStatus
  @observable activeCommunity = {
    id: undefined,
  }
  @observable courseThemeData = defaultCourseThemeData

  @observable showOverviewPage = false

  @observable mainSideMenuOpen = true
  @observable membershipNavOpen = true

  @observable showHeaderLinks = true

  @observable browserTabTitle = ''

  @observable sellableForSuccess = {}

  @observable communityModalIsOpenedByDefault = false
  @observable root = null

  constructor(rootStore: PayerRootStore) {
    this.root = rootStore
    makeObservable(this)
    this.api = createCourseSessionApi(apiClient)
  }

  setSideMenuState = (state: boolean, pathname = '') => {
    // state: bool, pathname?: string

    if (pathname) {
      const menuShouldBeHide =
        pathname.includes('payer/sellers/') ||
        ((pathname.includes('payer/courses/') || pathname.match(/payer\/s\/.+\/courses\//)) &&
          pathname.split('/').length > 3)

      this.setMainSideMenuOpen(!menuShouldBeHide)
    } else {
      this.setMainSideMenuOpen(state)
    }
  }

  /** actions  */
  @action setActiveLesson = (activeLesson) => (this.activeLesson = activeLesson)
  @action setActiveLessonStatus = (activeLessonStatus) => (this.activeLessonStatus = activeLessonStatus)
  @action setActiveCommunity = (community) => {
    this.resetActiveLesson()
    this.activeCommunity = community
    this.setQueryParams()
    this.setMobileMenuActive(false)
  }
  @action setBrowserTabTitle = () => (this.browserTabTitle = document.title)
  @action setCourseSession = (courseSession) => (this.courseSession = courseSession)
  @action setCourseThemeData = (courseThemeData) => (this.courseThemeData = courseThemeData)
  @action setCmpProps = (cmpProps) => (this.cmpProps = cmpProps)
  @action setInited = (inited) => (this.inited = inited)
  @action setLessons = (lessons) => (this.lessons = lessons)
  @action setLoading = (loading) => (this.loading = loading)
  @action setMainSideMenuOpen = (mainSideMenuOpen) => (this.mainSideMenuOpen = mainSideMenuOpen)
  @action setMembershipNavState = (membershipNavOpen) => (this.membershipNavOpen = membershipNavOpen)
  @action setMobileMenuActive = (mobileMenuActive) => (this.mobileMenuActive = mobileMenuActive)
  @action setMobileSideMenuActive = (mobileSideMenuActive) => (this.mobileSideMenuActive = mobileSideMenuActive)
  @action setProduct = (product) => (this.product = product)
  @action setSellableForSuccess = (sellableForSuccess) => (this.sellableForSuccess = sellableForSuccess)
  @action setShowOverviewPage = (showOverviewPage) => (this.showOverviewPage = showOverviewPage)
  @action setShowRetakeQuiz = (showRetakeQuiz) => (this.showRetakeQuiz = showRetakeQuiz)
  @action setShowQuizResults = (showQuizResults) => (this.showQuizResults = showQuizResults)
  @action setShowQuizResultModal = (showQuizResultModal) => (this.showQuizResultModal = showQuizResultModal)
  @action setCommunityModalIsOpenedByDefault = (communityModalIsOpenedByDefault) =>
    (this.communityModalIsOpenedByDefault = communityModalIsOpenedByDefault)
  /** actions  */

  @action resetActiveCommunity = () => {
    this.activeCommunity = {
      id: undefined,
    }
  }

  prepareCourse = async (cmpProps) => {
    const { historyPush, historyReplace, productSlug, username, pathname } = cmpProps

    this.setInited(false)
    this.setLoading(true)
    this.setCmpProps({
      historyPush,
      historyReplace,
      productSlug,
      username,
      pathname,
    })
    // TODO: SSR - must we get lesson_id from location here?
    const { course_session_id: courseSessionId, lesson_id: lessonId, community_id: communityId } = getSearchParams()

    const { data: courseSession, success: courseSessionSuccess } = await this.root?.courseSessionsStore.fetchItem(
      courseSessionId,
      {
        productSlug: productSlug || cmpProps.productId,
      }
    )

    if (courseSessionSuccess) {
      const { product, id, seller, sellableId } = courseSession

      await this.root?.sellablesStore.fetchItem(sellableId)

      const { isNeedCheckAccess, accessLimitIntervalId } = this.root?.productSessionStore

      if (isNeedCheckAccess(username)) {
        const { success } = await this.checkAccess(sellableId, true)

        if (success && !accessLimitIntervalId) {
          this.root.productSessionStore.accessLimitIntervalId = setInterval(
            () => this.checkAccess(sellableId),
            CHECK_ACCESS_DELAY
          )
        }
      }

      await this.root?.lessonStatusesStore.fetchFullList({ courseSessionId: id })

      this.setCourseSession(courseSession)
      this.setProduct(product)

      const sellerId = seller?.id
      const { courseThemeId, id: productId } = product || {}

      if (courseThemeId) {
        const { data: courseTheme, success: courseThemeSuccess } = await this.root?.courseThemesStore.fetchItem(
          courseThemeId,
          {
            sellerId,
          }
        )

        const courseThemeData =
          courseThemeSuccess && courseTheme && !isEmpty(courseTheme) ? courseTheme : { form: COURSE_FORMS.default }

        this.setCourseThemeData(courseThemeData)
      } else {
        this.setCourseThemeData({ form: COURSE_FORMS.default })
      }

      const { favicon, showFavicon } = this.courseThemeData.prefs || {}
      if (showFavicon) {
        setFavicon(favicon || DEFAULT_FAVICON)
      }

      const isCommunityAppActive = this.isAppActive(COMMUNITY_KEY)

      if (isCommunityAppActive) {
        if (communityId) {
          await this.root?.communityMemberStore.fetchItem(communityId, { skipErrorNotific: true })
          const { item: member } = this.root?.communityMemberStore
          const isCommunityOwner = member && member.role === COMMUNITY_MEMBERS_ROLES.owner
          if (String(communityId) === String(member.discussionId) && !isCommunityOwner) {
            const resp = await this.root?.communitiesStore.fetchItem(communityId)
            const { data, success } = resp || {}

            if (success) {
              this.setActiveCommunity(data)
            }
          } else {
            this.setCommunityModalIsOpenedByDefault(true)
          }
        }
      }

      const { data: lessons, success: lessonsSuccess } = await this.root?.lessonsStore.fetchFullList({
        courseSessionId: id,
      })
      if (lessonsSuccess) {
        const { list = [] } = lessons || {}
        this.setLessons(getNestedChildren(list, null))

        if (communityId && isCommunityAppActive) {
          this.setInited(true)
          this.setLoading(false)
        } else {
          this.openStaticPageOrFirstLesson(lessonId)
        }
        this.root?.viewLogsStore.trackViewLog({
          actionType: 'payer_course',
          sellerId,
          productId,
        })
      }
    }
  }

  checkAccess = async (sellableId, isInitialCheck = false) => {
    const { checkAccess, toggleAccessDeniedModal, clearIntervalAccessLimitIntervalId } = this.root?.productSessionStore

    const resp = await checkAccess(sellableId)
    if (!resp.success) {
      clearIntervalAccessLimitIntervalId()

      if (isInitialCheck) {
        this.root?.productSessionStore.setShowAccessLimitedModalMode(ACCESS_LIMITED_MODAL_MODE.tryToRejoin)
      } else {
        this.root?.productSessionStore.setShowAccessLimitedModalMode(ACCESS_LIMITED_MODAL_MODE.anotherPersonViewing)
      }
      toggleAccessDeniedModal(true)
    }
    return resp
  }

  openStaticPageOrFirstLesson = (lessonId) => {
    if (!lessonId && this.courseThemeData.form !== COURSE_FORMS.default && this.courseThemeData.prefs.showOverview) {
      const { name } = this.courseSession.product || {}
      this.setShowOverviewPage(true)
      this.setInited(true)
      this.setLoading(false)
      if (name) document.title = name
    } else {
      this.selectFirstLesson(lessonId)
    }
  }

  refetchLessonStatuses = async () => {
    const resp = await this.root?.lessonStatusesStore.fetchFullList({ courseSessionId: this.courseSession.id })
    if (resp.success) {
      this.setActiveLessonStatus(this.getLessonStatus(this.activeLesson?.id))
    }
  }

  @action selectFirstLesson = (lessonId) => {
    this.selectLesson(lessonId || this.detectFirstLessonId())
  }

  @action detectFirstLessonId = (lessons = null) => this.detectFirstLessonIdDeep(lessons || this.lessons)

  @action detectFirstLessonIdDeep = (lessons) => {
    // with this deep detecting we can do a lot of features in the future
    // like detect first not passed, detect first with quiz OR even
    // open for all payers lesson which seller select as promotion
    const detected = false
    let deepResult

    for (let i = 0; i < lessons.length; i++) {
      if (lessons[i].isCategory) {
        deepResult = this.detectFirstLessonIdDeep(lessons[i].children)
        if (deepResult) {
          return deepResult
        }
      } else if (lessons[i].active && lessons[i].id) {
        return lessons[i].id
      } else {
        lessons.shift()
        deepResult = this.detectFirstLessonIdDeep(lessons)
        if (deepResult) {
          return deepResult
        }
      }
    }

    return detected
  }

  detectSellableItem = async () => {
    const { assignedOrderId } = this.activeLessonStatus
    const { fetchFullList } = this.root?.sellablesStore
    if (assignedOrderId) {
      await fetchFullList({ orderId: assignedOrderId })
      const { list } = this.root?.sellablesStore
      this.setSellableForSuccess(list[0])
    }
  }

  selectLesson = (lessonId, handleScrollChange = () => {}, shouldSetQueryParams = false) => {
    const { lessonIsFinished, shouldCompleteQuiz } = this.viewConfig
    const selectEnabled = !lessonIsFinished || !shouldCompleteQuiz

    if (lessonId && selectEnabled) {
      this.resetActiveCommunity()
      this.root?.lessonsStore.fetchItem(lessonId, { courseSessionId: this.courseSession.id }).then((resp) => {
        this.setActiveLesson(resp.data || {})
        this.setActiveLessonStatus(this.getLessonStatus(this.activeLesson.id))
        this.setQueryParams(shouldSetQueryParams)
        this.setMobileMenuActive(false)
        this.setMobileSideMenuActive(false)
        this.setShowRetakeQuiz(false)
        this.setShowQuizResults(false)
        this.detectSellableItem()
        if (!this.activeLesson.isCategory) {
          this.root?.quizzesStore.fetchItem(this.courseSession.id, lessonId)
        }
        if (this.viewConfig.widgets.comments) {
          this.root?.commentsStore.fetchFullList({
            courseSessionId: this.courseSession.id,
            lessonId,
          })
        }
        this.setInited(true)
        this.setLoading(false)
        if (this.activeLesson.name) {
          document.title = this.activeLesson.name
        }

        if (handleScrollChange && desktopResolution) {
          handleScrollChange()
        } else {
          window.scrollTo(0, 0)
        }
      })
    }
  }

  handleReset = async (cmpProps, id) => {
    const { resetCourse, resetLesson } = this.api
    const activeLessonId = this.activeLesson.id
    const courseSessionId = id || this.courseSession.id

    const apiCall = activeLessonId ? resetLesson : resetCourse
    await apiCall(courseSessionId, activeLessonId)
    await this.prepareCourse(cmpProps)
    await this.root?.courseSessionsStore.fetchList()
  }

  @action setPrevLesson = () => {
    this.selectLesson(this.prevLessonId)
  }
  @action setNextLesson = () => {
    this.selectLesson(this.nextLessonId)
  }

  finishLesson = () => {
    this.root?.lessonsStore
      .finishLesson(this.activeLesson.id, { courseSessionId: this.courseSession.id })
      .then((resp) => {
        if (resp.success) {
          this.refetchLessonStatuses()
          this.refetchSession()
        }
      })
    if (this.activeLesson.quizEnabled) {
      window.scrollTo(0, 0)
    }
  }

  refetchSession = async () => {
    const { course_session_id: courseSessionId } = getSearchParams()
    const { data: courseSession = {} } =
      (await this.root?.courseSessionsStore.fetchItem(courseSessionId, {
        productSlug: this.cmpProps.productSlug,
      })) || {}
    this.setCourseSession(courseSession)
  }

  resetActiveLesson = () => {
    this.setActiveLesson({})
    this.setActiveLessonStatus({})
  }

  setOverviewPage = () => {
    this.setShowOverviewPage(true)
    this.resetActiveLesson()
    this.setQueryParams(true)

    const { name } = this.courseSession.product || {}

    if (name) {
      document.title = name
    }
  }

  get isOverviewPage() {
    const { location: { search = '' } = {} } = window
    const isOverviewRoute =
      !search.includes('lesson_id') && !search.includes('community_id') && search.includes('course_session_id')
    return isOverviewRoute
  }

  setQueryParams = (shouldPushHistory = false) => {
    const { comment_id: commentId } = getSearchParams()

    const queryStringObj = {
      course_session_id: this.courseSession.id,
      lesson_id: undefined,
      community_id: undefined,
      comment_id: undefined,
    }

    if (this.activeLesson.id) queryStringObj.lesson_id = this.activeLesson.id
    if (this.activeCommunity.id) queryStringObj.community_id = this.activeCommunity.id
    if (commentId) queryStringObj.comment_id = commentId

    const newQueryString = queryString.stringify(queryStringObj, { sort: false })

    if (shouldPushHistory && this.courseThemeData.form !== COURSE_FORMS.default) {
      this.cmpProps.historyPush({ search: newQueryString })
    } else {
      this.cmpProps.historyReplace({ search: newQueryString })
    }
  }

  toggleMobileMenu = () => {
    this.setMobileMenuActive(!this.mobileMenuActive)
  }

  toggleSideMobileMenu = () => {
    this.setMobileSideMenuActive(!this.mobileSideMenuActive)
  }

  getLessonStatus = (lessonId) =>
    this.root?.lessonStatusesStore.list.find((status) => status.lessonId === lessonId) || {}

  checkIfHasRequiredPrevQuizzes = (lessons, lessonId) => {
    const detected = false
    let deepResult
    for (let i = 0; i < lessons.length; i += 1) {
      const lessonStatus = this.getLessonStatus(lessons[i].id)
      const isPublished = (lessons.find((item) => String(item.id) === String(lessons[i].id)) || {}).active

      if (lessons[i].id === lessonId) {
        return 'no'
      }
      if (lessonStatus.quizRequiredToGoToNext && isPublished) {
        return 'yes'
      }
      if (lessons[i].isCategory && lessons[i].children.length > 0) {
        deepResult = this.checkIfHasRequiredPrevQuizzes(lessons[i].children, lessonId)
        if (deepResult) {
          return deepResult
        }
      }
    }

    return detected
  }

  hasRequiredPrevQuizzes = (lessonId) => this.checkIfHasRequiredPrevQuizzes(this.lessons || [], lessonId) === 'yes'

  @action submitQuiz = (quizData) => {
    const quizDataJs = Object.fromEntries(toJS(quizData))
    const keysList = Object.keys(quizDataJs)
    const indexedQuizAnswers = keysList.length > 0 ? quizDataJs : []

    const data = {
      quizAnswersAttributes: indexedQuizAnswers,
    }

    this.root?.quizzesStore.submitQuiz(this.courseSession.id, this.activeLesson.id, data).then(
      ({
        data: quiz = {
          autoChecked: false,
        },
      }) => {
        this.setShowRetakeQuiz(false)
        if (quiz.autoChecked) {
          this.toggleQuizResultModal()
        }
        this.refetchLessonStatuses()
      }
    )
  }

  toggleQuizResultModal = () => {
    this.setShowQuizResultModal(!this.showQuizResultModal)
    this.setShowRetakeQuiz(false)
  }

  handleRetakeQuiz = () => {
    this.setShowRetakeQuiz(true)
    this.setShowQuizResultModal(false)
  }

  toggleQuizResults = () => {
    this.setShowQuizResults(!this.showQuizResults)
    this.setShowQuizResultModal(false)
  }

  refreshCountdown = () => {
    this.refetchLessonStatuses()
  }

  continueCourse = () => {
    this.toggleQuizResultModal()
    this.setNextLesson()
  }

  isAppActive = (key) => {
    const { seller } = this.courseSession || defaultCourseSession
    const { optionKeys = [] } = this.root?.sellersStore.list.find(({ id }) => seller.id === id) || {}
    return optionKeys.includes(key)
  }

  @computed get seller() {
    return this.courseSession.seller
  }

  @computed get prevLessonId() {
    return prevLessonId(this.activeLesson, this.lessons)
  }

  @computed get nextLessonId() {
    return nextLessonId(this.activeLesson, this.lessons)
  }

  @computed get viewConfig() {
    const {
      seller = {
        id: undefined,
      },
      publishState,
      blocked: accessBlocked,
    } = this.courseSession || defaultCourseSession

    const {
      commentsAllowed,
      fbCommentsAllowed,
      contentPageBeforeId,
      contentPageId,
      contentPageAfterId,
      static: isStatic,
      active: isPublished,
    } = this.activeLesson || defaultActiveLesson

    const {
      shouldCompleteQuiz,
      assignedOrderId,
      publishState: lessonPublishState,
      publishDate: lessonPublishDate,
      endDate: lessonEndDate,
    } = this.activeLessonStatus || {}

    const layout = this.courseThemeData.form
    const endDateFormatted = lessonEndDate && moment(lessonEndDate)
    const endDatePassed = endDateFormatted && moment().isAfter(endDateFormatted)
    const availableDateFormatted = moment(lessonPublishDate).toDate()
    const accessCanceled = publishState === COURSE_SESSION_PUBLISH_STATES.publishCanceled
    const hasPaymentProblem = publishState === COURSE_SESSION_PUBLISH_STATES.publishPaused

    const [lessonIsFinished, lessonIsPublished, lessonIsViewed] = [
      lessonPublishState === LESSON_STATUSES_PUBLISH_STATES.finished,
      lessonPublishState === LESSON_STATUSES_PUBLISH_STATES.published,
      lessonPublishState === LESSON_STATUSES_PUBLISH_STATES.viewed,
    ]

    const lessonIsAvailable = lessonIsFinished || lessonIsPublished || lessonIsViewed

    const shouldCompletePrevQuizzes = this.hasRequiredPrevQuizzes(this.activeLesson.id)
    const showPrevBtn = !!this.prevLessonId
    const showNextBtn = !!this.nextLessonId
    const showFinishBtn =
      !lessonIsFinished &&
      lessonIsAvailable &&
      !shouldCompletePrevQuizzes &&
      !isStatic &&
      !hasPaymentProblem &&
      !accessCanceled
    const contentPagePreviewScope = `payer/course_sessions/${this.courseSession.id}/lessons/${this.activeLesson.id}`
    const { overByTime } = this.courseSession

    const { optionKeys = [] } = this.root?.sellersStore.list.find(({ id }) => seller.id === id) || {}
    const isQuizAppActivated = optionKeys.includes(LESSON_QUIZ_OPTION_KEY)
    const displayQuizWidget =
      lessonIsFinished &&
      !shouldCompletePrevQuizzes &&
      !hasPaymentProblem &&
      !accessCanceled &&
      !accessBlocked &&
      isQuizAppActivated &&
      !overByTime &&
      this.activeLesson.quizEnabled
    const displaySuccess = !!assignedOrderId
    const displayContentWidget = !(lessonIsFinished && shouldCompleteQuiz) && !this.showRetakeQuiz
    const isCommentsAppActivated = optionKeys.includes(LESSON_COMMENTS_OPTION_KEY)
    const displayCommentsWidget =
      (commentsAllowed || fbCommentsAllowed) &&
      !shouldCompletePrevQuizzes &&
      lessonIsAvailable &&
      !accessCanceled &&
      !hasPaymentProblem &&
      !accessBlocked &&
      isCommentsAppActivated

    // we have 8 variations of content page to display
    // here we should decide which we will display
    const contentToDisplayKey = () => {
      if (accessBlocked) {
        return 'blockedCont'
      }
      if (overByTime) {
        return 'overByTime'
      }
      if (isEmpty(this.activeLessonStatus)) {
        return 'noStatusCont'
      }
      if (accessCanceled || hasPaymentProblem) {
        return 'canceledCont'
      }
      if (endDatePassed && contentPageAfterId) {
        return 'contentPageAfterCont'
      }
      if (endDatePassed) {
        return 'accessExpiredCont'
      }
      if (shouldCompletePrevQuizzes) {
        return 'completeQuizCont'
      }
      if (!lessonIsAvailable && contentPageBeforeId) {
        return 'contentPageBeforeCont'
      }
      if (!isPublished) {
        return 'contentIsNotAvailable'
      }
      if (!lessonIsAvailable) {
        return 'willBeAvailableCont'
      }
      if (lessonIsAvailable && contentPageId) {
        return 'contentPageCont'
      }
    }

    const fbAppId = getElopageConfig('fbAppId')

    return {
      layout,
      backLink: PAYER_PRODUCTS_PATH,
      isShop: false,
      isPayerCabinet: true,

      facebookAppId: fbAppId,
      avatarUrl: (this.root?.userStore?.item.avatar || {}).s200,
      currentProfileId: this.root?.payerStore?.item?.id,
      currentProfileType: 'payer',
      contentPagePreviewScope,

      lessonIsFinished,
      lessonIsAvailable,
      shouldCompleteQuiz,
      shouldCompletePrevQuizzes,

      accessCanceled,
      hasPaymentProblem,
      endDatePassed,
      availableDateFormatted,

      contentToDisplayKey: contentToDisplayKey(),
      showPrevBtn,
      showNextBtn,
      showFinishBtn,

      widgets: {
        quiz: displayQuizWidget,
        success: displaySuccess,
        content: displayContentWidget,
        comments: displayCommentsWidget,
      },
    }
  }
}
