import { ApiClientV2Default } from 'utils/api-client/api-client.utils'

//TODO: remove shared import after refactoring of edit profile modal
import { VatUserRegistrationsStore } from 'shared/stores/vatUserRegistrations.store'

import { TableFiltersStore } from 'shared/stores/tableFilters.store'
import { CommunityMemberStore } from 'shared/stores/communityMember.store'
import { CommunitiesStore } from 'shared/stores/communities.store'
import { CommunityMembersStore } from 'shared/stores/communityMembers.store'
import { CommunityPostsStore } from 'shared/stores/communityPosts.store'
import { AudioPlayersStore } from 'shared/stores/audioPlayers.store'
import { AffiliateProgramsStore } from 'shared/stores/affiliatePrograms.store'
import { CommunityLabelsStore } from 'shared/stores/communityLabels.store'
import { CommunitiesFeedPostsStore } from 'shared/stores/communitiesFeedPosts.store'
import { CommunitiesMembersStore } from 'shared/stores/communitiesMembers.store'
import { ExperimentsStore } from 'shared/stores/experiments.store'

import { CommentsStore } from './comments.store'

import { SellerStore } from './seller.store'
import { SellersStore } from './sellers.store'
import { MembershipThemesStore } from './membershipThemes.store'
import { UserStore } from './user.store'
import { PayerStore } from './payer.store'
import { SellerFontsStore } from './sellerFonts.store'
import { OrdersStore } from './orders.store'
import { OrderVatChangeStore } from './orderVatChange.store'
import { CurrenciesStore } from './currencies.store'
import { SellablesStore } from './sellables.store'
import { SellableItemsStore } from './sellableItems.store'
import { CourseViewsStore } from './courseViews.store'
import { PayerAppStore } from './payerApp.store'
import { InvoicesStore } from './invoices.store'
import { ContentPageStore } from './contentPage.store'
import { ViewLogsStore } from './viewLogs.store'
import { CoversStore } from './covers.store'
import { ProductSessionStore } from './productSession.store'
import { ProductsStore } from './products.store'
import { CourseSessionsStore } from './courseSessions.store'
import { MembershipSessionsStore } from './membershipSessions.store'
import { LessonsStore } from './lessons.store'
import { QuizzesStore } from './quizzes.store'
import { ProductGroupsStore } from './productGroups.store'
import { CourseThemesStore } from './courseThemes.store'
import { UpsellsStore } from './upsells.store'

import { ProfilesStore } from './profiles.store'
import { UserProfilesStore } from './userProfiles.store'
import { BankAccountsStore } from './bankAccounts.store'
import { CountriesStore } from './countries.store'
import { TransfersStore } from './transfers.store'

import { LessonStatusesStore } from './lessonStatuses.store'

import { ModalsManager } from './modalsManager.store'

export class PayerRootStore {
  apiClient: ApiClientV2Default

  sellerStore: SellerStore
  membershipThemesStore: MembershipThemesStore

  communityLabelsStore: CommunityLabelsStore
  communitiesFeedPostsStore: CommunitiesFeedPostsStore
  communitiesMembersStore: CommunitiesMembersStore
  experimentsStore: ExperimentsStore
  sellersStore: SellersStore
  userStore: UserStore
  payerStore: PayerStore
  sellerFontsStore: SellerFontsStore
  ordersStore: OrdersStore
  orderVatChangeStore: OrderVatChangeStore
  currenciesStore: CurrenciesStore
  sellablesStore: SellablesStore
  sellableItemsStore: SellableItemsStore
  courseViewsStore: CourseViewsStore
  countriesStore: CountriesStore
  invoicesStore: InvoicesStore
  transfersStore: TransfersStore
  contentPageStore: ContentPageStore
  viewLogsStore: ViewLogsStore
  coversStore: CoversStore
  productsStore: ProductsStore
  productSessionStore: ProductSessionStore
  courseSessionsStore: CourseSessionsStore
  membershipSessionsStore: MembershipSessionsStore
  lessonStatusesStore: LessonStatusesStore
  lessonsStore: LessonsStore
  quizzesStore: QuizzesStore
  productGroupsStore: ProductGroupsStore
  tableFiltersStore: TableFiltersStore
  courseThemesStore: CourseThemesStore
  communityMembersStore: CommunityMembersStore
  communityPostsStore: CommunityPostsStore
  communitiesStore: CommunitiesStore
  communityMemberStore: CommunityMemberStore
  vatUserRegistrationsStore: VatUserRegistrationsStore
  audioPlayersStore: AudioPlayersStore
  affiliateProgramsStore: AffiliateProgramsStore
  commentsStore: CommentsStore
  upsellsStore: UpsellsStore

  payerAppStore: PayerAppStore
  profilesStore: ProfilesStore
  userProfilesStore: UserProfilesStore
  bankAccountsStore: BankAccountsStore

  modalsManager: ModalsManager

  constructor() {
    this.apiClient = new ApiClientV2Default()

    this.sellerStore = new SellerStore(this)
    this.sellersStore = new SellersStore()

    this.membershipThemesStore = new MembershipThemesStore()
    this.userStore = new UserStore()
    this.payerStore = new PayerStore()
    this.sellerFontsStore = new SellerFontsStore()
    this.ordersStore = new OrdersStore()
    this.orderVatChangeStore = new OrderVatChangeStore()
    this.currenciesStore = new CurrenciesStore()
    this.sellablesStore = new SellablesStore()
    this.sellableItemsStore = new SellableItemsStore()
    this.invoicesStore = new InvoicesStore()
    this.transfersStore = new TransfersStore()
    this.courseSessionsStore = new CourseSessionsStore()
    this.membershipSessionsStore = new MembershipSessionsStore()
    this.lessonStatusesStore = new LessonStatusesStore()
    this.lessonsStore = new LessonsStore()
    this.quizzesStore = new QuizzesStore()
    this.productGroupsStore = new ProductGroupsStore()
    this.productsStore = new ProductsStore()
    this.courseThemesStore = new CourseThemesStore()
    this.courseViewsStore = new CourseViewsStore(this)
    this.productSessionStore = new ProductSessionStore(this)
    this.contentPageStore = new ContentPageStore(this)
    this.communityMembersStore = new CommunityMembersStore()
    this.communityPostsStore = new CommunityPostsStore()
    this.upsellsStore = new UpsellsStore()

    // shared
    this.vatUserRegistrationsStore = new VatUserRegistrationsStore()
    this.audioPlayersStore = new AudioPlayersStore()
    this.affiliateProgramsStore = new AffiliateProgramsStore()
    this.communityMemberStore = new CommunityMemberStore()
    this.communitiesStore = new CommunitiesStore()
    this.commentsStore = new CommentsStore()

    this.viewLogsStore = new ViewLogsStore()
    this.coversStore = new CoversStore()

    this.payerAppStore = new PayerAppStore()
    this.userProfilesStore = new UserProfilesStore()
    this.bankAccountsStore = new BankAccountsStore()
    this.profilesStore = new ProfilesStore(this)
    this.countriesStore = new CountriesStore(this)

    this.modalsManager = new ModalsManager()
    this.tableFiltersStore = new TableFiltersStore()
    this.communityLabelsStore = new CommunityLabelsStore()
    this.communitiesFeedPostsStore = new CommunitiesFeedPostsStore()
    this.communitiesMembersStore = new CommunitiesMembersStore()
    this.experimentsStore = new ExperimentsStore()
  }
}
