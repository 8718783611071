import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import { removeTokens } from 'utils/requests.utils'
import { LocalStorageService } from 'utils/local-storage.utils'
import { FINGER_PRINT_KEY } from 'utils/fingerprint.utils'

import { EloLink } from '@elo-ui/components/elo-link'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloSidebar } from '@elo-ui/components/elo-sidebar'
import { EloTopBar } from '@elo-ui/components/elo-top-bar'
import { EloLoadingSpinner } from '@elo-ui/components/elo-loading-spinner'

import { getAllowedLanguages } from 'constants/general.constants'
import { getProfileDetails, PROFILE_TYPES, STARTED_WITH_ELOPAGE_KEY } from 'constants/profile.constants'
import { SIDEBAR_ITEMS, FOOTER_LOGO, LOGO } from '../../../constants/sidebar'
import { TOPBAR_ACTIONS } from '../../../constants/topbar'

import { MODALS_IDS } from '../../modals'

import { usePayerStore } from '../../../hooks/use-payer-stores'
import { usePayerNavigation } from '../../../hooks/use-payer-navigation'
import { logOut } from '../../../../app/api/session.api'

import './page-wrapper.scss'

export const PageWrapper = observer(({ children }) => {
  const location = useLocation()
  const { payerAppStore, userStore, sellersStore, currenciesStore, modalsManager } = usePayerStore()
  const { navigationItems, activeNavigationItem } = usePayerNavigation(SIDEBAR_ITEMS, { location })

  const { isMobile, isLoading, pageClasses, showSidebar, showTopBar } = payerAppStore
  const {
    item: { locale, avatar, name, email, id, profileTypes },
  } = userStore

  const profileDetails = getProfileDetails()[PROFILE_TYPES['payer']]
  const isAnyExceptPayerProfileExist = profileTypes.some((item) => item !== PROFILE_TYPES['payer'])

  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  useEffect(() => {
    if (!currenciesStore.list.length) {
      currenciesStore.fetchFullList({ sortDir: 'asc' })
    }
  }, [])

  const handleUserLogOut = () => {
    logOut().then(() => {
      window.sessionStorage.removeItem(STARTED_WITH_ELOPAGE_KEY)
      removeTokens(false)
      LocalStorageService.removeItem(FINGER_PRINT_KEY, true)
    })
  }

  const sidebarFooterContent = () => {
    if (!isMobile) return null

    return (
      <EloLink className='sidebar-footer__link' as='a' href='#' onClick={handleProfileButtonClick}>
        {I18n.t('react.shared.profile_type_account', { profileType: profileDetails?.title })}
      </EloLink>
    )
  }

  const topBarActions = () => {
    const isProfileSwitcherVisible = isMobile && isAnyExceptPayerProfileExist
    return {
      ...TOPBAR_ACTIONS,
      signOut: {
        label: I18n.t('react.shared.sign_out'),
        link: {
          href: '/',
          onClick: handleUserLogOut,
        },
      },
      ...(isProfileSwitcherVisible && {
        switchProfile: {
          label: I18n.t('react.shared.switch_accounts'),
          link: {
            onClick: () => modalsManager.open(MODALS_IDS.profileSwitchingModal),
          },
        },
      }),
    }
  }

  const topBarCustomContent = () => {
    if (isMobile) return null

    return (
      <EloButton variant='secondary' onClick={handleProfileButtonClick}>
        {I18n.t('react.shared.profile_type_account', { profileType: profileDetails?.title })}
      </EloButton>
    )
  }

  const handleProfileButtonClick = () => {
    modalsManager.open(MODALS_IDS.profileSwitchingModal)
  }

  const classes = classNames('page-wrapper', ...pageClasses, {
    'page-wrapper--sidebar-open': isSidebarOpen,
    'page-wrapper--mobile': isMobile,
    'page-wrapper--without-sidebar': !showSidebar,
    'page-wrapper--without-topbar': !showTopBar,
  })

  const copyrightText = `@${new Date().getFullYear()} ablefy`
  const ALLOWED_LANGUAGES = getAllowedLanguages()

  return (
    <div className={classes}>
      {!id || isLoading ? (
        <div className='loader-wrapper'>
          <EloLoadingSpinner />
        </div>
      ) : (
        <>
          <div className='menu-container'>
            {showTopBar && (
              <EloTopBar
                isMobile={isMobile}
                languages={{
                  options: Object.values(ALLOWED_LANGUAGES),
                  onChange: (value) => userStore.updateItem({ locale: value }),
                }}
                currentLanguage={locale}
                customContent={topBarCustomContent()}
                actions={topBarActions()}
                user={{
                  avatar: avatar.s200,
                  name: name,
                  email: email,
                }}
              />
            )}
            {showSidebar && (
              <EloSidebar
                items={navigationItems}
                activeItem={activeNavigationItem}
                isOpen={isSidebarOpen}
                onOpenCLick={(value) => setIsSidebarOpen(value)}
                footerLogo={FOOTER_LOGO}
                isMobile={isMobile}
                rootLink='/'
                logo={sellersStore.item.domainLogo || sellersStore.detectedDomainLogo || LOGO}
                footerContent={sidebarFooterContent()}
                copyright={copyrightText}
              />
            )}
          </div>
          <div className='main-content'>{children}</div>
        </>
      )}
    </div>
  )
})
