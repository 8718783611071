import { makeAutoObservable } from 'mobx'
import { Nullable, SelectOption } from 'types/helpers'

import {
  formatCountryListOptions,
  formatCountryListOptionsForSignUp,
  formatStateListOptions,
} from 'utils/countries.utils'

import { CountriesApi, createCountriesApi, Country, State } from '../api/countries.api'

const defaultCountry: Country = {
  id: 'DE',
  name: 'Germany',
  alpha2: 'DE',
  euMember: true,
  region: '',
}

const defaultState: State = {
  id: '',
  name: '',
  alpha2: '',
}

type CountryOption = SelectOption<Country>
type StateOption = SelectOption<State>

export class CountriesStore {
  storeName = 'CountriesStore'
  declare api: CountriesApi
  private memoCountry: Nullable<Country> = null
  list: Country[] = []
  states = new Map<string, State[]>()

  constructor(root) {
    makeAutoObservable(this)
    this.api = createCountriesApi(root.apiClient)
  }

  setList(list: Country[]) {
    this.list = list
  }

  setMemoCountry(country: Country) {
    this.memoCountry = country
  }

  async fetchStates(id: string): Promise<void> {
    if (!this.states.has(id)) {
      const resp = await this.api.fetchStates(id)
      if (resp.responseType === 'success') {
        this.states.set(id, resp.data)
      }
    }
  }

  async fetchList(): Promise<void> {
    if (this.list.length) {
      return
    }

    const resp = await this.api.fetchList()
    if (resp.responseType === 'success') {
      this.list = resp.data
    } else {
      this.setList([])
    }
  }

  get withoutChEuCountries() {
    return this.list.filter(({ euMember }) => euMember)
  }

  get withChNonEuCountries() {
    return this.list.filter(({ euMember }) => !euMember)
  }
  get countrySelectOptionsForSignUp() {
    return formatCountryListOptionsForSignUp(this.list)
  }
  get countrySelectOptions() {
    return formatCountryListOptions(this.list)
  }
  get alpha2CountryMap() {
    return this.list.reduce((acc, country) => {
      acc[country.alpha2] = country
      return acc
    }, {})
  }
  getStates = (countryKey: string): State[] => this.states.get(countryKey) ?? ([] as State[])

  getState = (countryKey: string, stateKey: string): State => {
    const states = this.getStates(countryKey)
    const state = states.find((state) => state.id === stateKey)

    return state ?? defaultState
  }

  getStatesSelectOptions = (countryKey: string): StateOption[] => {
    const states = this.getStates(countryKey)

    return formatStateListOptions(states)
  }

  getStatesSelectOption = (countryKey: string, stateKay: string): StateOption => {
    const state = this.getState(countryKey, stateKay)

    return {
      label: state.name,
      value: state.id,
      ...state,
    }
  }

  getCountry = (id: string): Country => {
    if (this.memoCountry?.id === id) {
      return this.memoCountry
    }

    const country = this.list.find((country) => country.id === id)
    this.setMemoCountry(country)

    if (!country) {
      console.error(`Country with id ${id} not found`)
    }

    return country ?? defaultCountry
  }

  getCountrySelectOption = (id: string): CountryOption => {
    const country = this.getCountry(id)

    return {
      label: country?.name,
      value: country?.alpha2,
      ...country,
    }
  }
}
