import { makeAutoObservable } from 'mobx'

export class PayerAppStore {
  constructor() {
    makeAutoObservable(this)
    this.checkMobileView()
  }

  isLoading = false
  isMobile = false
  pageClasses: string[] = []

  showTopBar = true
  showSidebar = true

  setIsLoading = (value: boolean) => (this.isLoading = value)
  setMobile = (value: boolean) => (this.isMobile = value)
  setShowTopBar = (value: boolean) => (this.showTopBar = value)
  setShowSideBar = (value: boolean) => (this.showSidebar = value)

  addPageClass = (value: string) => this.pageClasses.push(value)

  resetPageClasses = () => (this.pageClasses = [])

  checkMobileView = () => {
    const query = matchMedia('(max-width: 768px)')
    this.isMobile = query.matches

    this.setMobile(query.matches)

    query?.addEventListener('change', (e) => {
      this.setMobile(e.matches)
    })
  }
}
