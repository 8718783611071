import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { UpsellsApi, Upsell, UpsellResponse, createUpsellsApi } from '../api/upsells.api'

export class UpsellsStore {
  declare api: UpsellsApi
  item: Upsell = null

  constructor() {
    this.api = createUpsellsApi(apiClient)
    makeAutoObservable(this)
  }

  setItem(data: UpsellResponse) {
    this.item = data.upsell
  }

  fetchItem = async () => {
    const resp = await this.api.fetchItem()
    const { data, success } = resp || {}
    if (data && success) {
      this.setItem(data)
      return resp
    }
  }
}
