import React from 'react'
import classnames from 'classnames'

import { EloCloseIcon } from '@elo-ui/components/icons/regular'
import { EloRadioButton } from '@elo-ui/components/elo-radio-button'
import { EloAvatar } from '@elo-ui/components/elo-avatar'

import { TEST_IDS } from '@elo-ui/types'

import './elo-top-bar-profile-menu.scss'

interface Action {
  label: string
  subLabel?: string
  link: {
    href?: string
    target?: string
    onClick?: () => void
  }
}

interface Language {
  label: string
  value: string
}

interface Props {
  isMobile?: boolean
  isOpen: boolean
  user: {
    avatar: string
    name: string
    email: string
  }
  actions: {
    plan?: Action
    profile?: Action
    bankAccounts?: Action
    businessApp?: Action
    helpCenter?: Action
    support?: Action
    whatIsNew?: Action
    switchProfile?: Action
    signOut?: Action
  }
  currentLanguage: string
  languages: {
    onChange: (language: string) => void
    options: Language[]
  }
  onClose: () => void
}

export const EloTopBarProfileMenu: React.FC<Props> = ({
  isMobile = false,
  isOpen = false,
  user,
  actions,
  currentLanguage,
  languages,
  onClose,
}) => {
  const profileMenuClasses = classnames('elo-top-bar__profile-menu', {
    'elo-top-bar__profile-menu--open': isOpen,
    'elo-top-bar__profile-menu--mobile': isMobile,
    'elo-top-bar__profile-menu--desktop': !isMobile,
  })

  return (
    <div className={profileMenuClasses} data-testid={TEST_IDS.topBarProfileMenu}>
      <div className='elo-top-bar__profile-menu-header'>
        <button onClick={onClose} className='elo-top-bar__profile-menu-close'>
          <EloCloseIcon />
        </button>
      </div>
      <div className='elo-top-bar__profile-menu-content'>
        <div className='elo-top-bar__user-info'>
          <div className='elo-top-bar__user-avatar'>
            <EloAvatar size='large' src={user?.avatar} alt='avatar' />
          </div>
          <div className='elo-top-bar__user-name'>
            <span>{user?.name}</span>
            <span>{user?.email}</span>
          </div>
        </div>
        <div className='elo-top-bar__user-actions'>
          {actions?.plan && (
            <a {...actions?.plan?.link} className='elo-top-bar__user-action'>
              <span>{actions?.plan?.label}</span>
              <span>{actions?.plan?.subLabel}</span>
            </a>
          )}
          {actions?.profile && (
            <a {...actions?.profile?.link} className='elo-top-bar__user-action'>
              <span>{actions?.profile?.label}</span>
            </a>
          )}
          {actions?.bankAccounts && (
            <a {...actions?.bankAccounts?.link} className='elo-top-bar__user-action'>
              <span>{actions?.bankAccounts?.label}</span>
            </a>
          )}
        </div>
        <div className='elo-top-bar__user-actions'>
          {actions?.businessApp && (
            <a {...actions?.businessApp?.link} className='elo-top-bar__user-action'>
              <span>{actions?.businessApp?.label}</span>
            </a>
          )}
          {actions?.helpCenter && (
            <a {...actions?.helpCenter?.link} className='elo-top-bar__user-action'>
              <span>{actions?.helpCenter?.label}</span>
            </a>
          )}
          {actions?.support && (
            <a {...actions?.support?.link} className='elo-top-bar__user-action'>
              <span>{actions?.support?.label}</span>
            </a>
          )}
          {actions?.whatIsNew && (
            <a {...actions?.whatIsNew?.link} className='elo-top-bar__user-action'>
              <span>{actions?.whatIsNew?.label}</span>
            </a>
          )}
        </div>
        <div className='elo-top-bar__user-language'>
          {languages?.options?.map((language, index) => (
            <EloRadioButton
              key={index}
              checked={currentLanguage === language.value}
              onChange={() => languages?.onChange(language.value)}
            >
              {language.label}
            </EloRadioButton>
          ))}
        </div>
        {actions?.switchProfile && (
          <div className='elo-top-bar__user-actions'>
            <span className='elo-top-bar__user-action' onClick={actions.switchProfile.link.onClick}>
              {actions.switchProfile.label}
            </span>
          </div>
        )}
        {actions?.signOut && (
          <div className='elo-top-bar__user-actions'>
            <a {...actions?.signOut?.link} className='elo-top-bar__user-action'>
              <span>{actions?.signOut?.label}</span>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}
