import React from 'react'
import { observer } from 'mobx-react'

import { EloModal, EloModalHeader, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloWarningIcon } from '@elo-ui/components/icons/regular'

import { MODALS_IDS } from '../index'

import { usePayerStore } from '../../../hooks/use-payer-stores'

import './reset-course-modal.scss'

export interface ResetCourseModalProps {
  onResetCourse: () => void
}

export const ResetCourseModal = observer(() => {
  const { modalsManager } = usePayerStore()
  const { isOpen, onResetCourse } = modalsManager.getModal<ResetCourseModalProps>(MODALS_IDS.resetCourseModal)

  const handleClose = () => {
    modalsManager.close(MODALS_IDS.resetCourseModal)
    window.location.reload()
  }

  const handleResetCourse = () => {
    onResetCourse()
    handleClose()
  }

  return (
    <EloModal className='reset-course-modal' isOpen={isOpen} onClose={handleClose}>
      <EloModalHeader className='reset-course-modal__header'>
        <span className='reset-course-modal__header-icon'>
          <EloWarningIcon size={32} />
        </span>
        {I18n.t('react.shared.confirm_course_reset')}
      </EloModalHeader>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton onClick={handleClose}>{I18n.t('react.shared.close')}</EloButton>
        <EloButton variant='danger' onClick={handleResetCourse}>
          {I18n.t('react.shared.button.reset_course')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
