import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as MobXProvider } from 'mobx-react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import 'libs/payer'
import 'libs/common/analytics'

import { I18nProvider } from '@elo-kit/components'

import { initSentry } from 'libs/common/sentry'
import { userSessionCookieKey } from 'libs/common/cookies'
import ErrorBoundary from 'ui/error-boundary/ErrorBoundary'
import { PAYER_STATSIG_CLIENT_KEYS } from 'libs/configs'

import { AdminMode } from 'shared/components/admin-mode/AdminMode'
import ApiBranch from 'shared/components/api-branch/ApiBranch'
import { UserSessionProvider } from 'shared/context/user-session-provider'

import { ACTIVE_PROFILES } from 'constants/profile.constants'

import { profile } from 'utils/profileHelper.utils'
import { handleInitCabinetFetching } from 'utils/requests.utils'

import { MembershipTheme } from './components/common/MembershipTheme'
import { MetaTags } from './components/common/MetaTags'
import { PageWrapper } from './components/common/page-wrapper'
import { CookieBot } from './components/common/CookieBot'
import { PayerStatsigProvider } from './providers/payer-statsig-provider'

import { ModalsRenderer } from './components/modals'

import { usePayerStore } from './hooks/use-payer-stores'

import { Routes } from './routes'

profile.setProfileType(ACTIVE_PROFILES.payer)

initSentry()

import './index.scss'

export const App = () => {
  const stores = usePayerStore()
  const { userStore, payerStore, countriesStore, experimentsStore } = stores

  useEffect(() => {
    handleInitCabinetFetching(userStore, payerStore, false)
    countriesStore.fetchList()
  }, [])

  return (
    <UserSessionProvider cookieName={userSessionCookieKey}>
      <PayerStatsigProvider clientKey={PAYER_STATSIG_CLIENT_KEYS[process.env.ENV]} experimentsStore={experimentsStore}>
        <I18nProvider>
          <MobXProvider {...stores}>
            <Router>
              <MetaTags />
              <PageWrapper>
                <Routes />
                <ModalsRenderer />
                <ApiBranch />
                <AdminMode userEmail={userStore?.item?.email} />
              </PageWrapper>
              <MembershipTheme />
            </Router>
            {/*<UserFlow />*/}
            <CookieBot />
            <ToastContainer />
          </MobXProvider>
        </I18nProvider>
      </PayerStatsigProvider>
    </UserSessionProvider>
  )
}

const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
  // </React.StrictMode>
)
