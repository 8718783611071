import { ApiClient } from 'utils/requests.utils'
import { Response } from 'types/responses'
import { Nullable, ProductType } from 'types/helpers'

const BASE_URL = '/payer/upsell'

type CoverFile = {
  s100: string
  s640: string
  s1200: string
  custom: string
  original: string
  name: string
  size: number
  contentType: string
  cdnUrl: string
  downloadUrl: string
}

type Cover = {
  id: number
  cover: string
  file: CoverFile
  fileCropH: number
  fileCropW: number
  fileCropX: number
  fileCropY: number
  fileName: Nullable<string>
  fileRemoteUrl: Nullable<string>
  link: Nullable<string>
  ownerableId: number
  ownerableType: string
  thumb: string
  type: string
  uploadedViaTe: Nullable<string>
  url: string
  uuid: string
  videoLink: Nullable<string>
  voucherTemplate: boolean
  createdAt: string
  updatedAt: string
}
export interface Upsell {
  productId: number
  form: ProductType
  covers: Cover[]
  name: string
  slug: string
  sellerUsername: string
}

export interface UpsellResponse {
  upsell: Upsell
}

export const createUpsellsApi = ({ GET }: ApiClient) => ({
  fetchItem: () => GET<Response<UpsellResponse>>(BASE_URL),
})

export type UpsellsApi = ReturnType<typeof createUpsellsApi>
